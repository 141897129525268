import { Component, Vue } from 'vue-property-decorator';
import BillingDetails from '@/views/finance-management/billing/billing-details/billing-details.vue';
import InvoiceDetails from '@/views/finance-management/finance-invoice/invoice-details/invoice-details.vue';
import ApprovalRecord from './components/approval-record/approval-record.vue';
import OsEmpty from './components/os-empty/os-empty.vue';
import { TagsViewModule } from '@/store/modules/tags-view';

@Component({
  name: 'TaskDetails',
  components: { OsEmpty, BillingDetails, InvoiceDetails, ApprovalRecord }
})
export default class TaskDetails extends Vue {
  public id: number = 0;
  public taskId: string = '';
  public businessId: number | null = null;
  public bizKey: string = '';
  public drawer: boolean = false;

  public componentName: string = '';
  public showApprovalRecord(): void {
    this.drawer = true;
  }
  public created(): void {
    if (this.$route.query.businessId) {
      this.businessId = Number(this.$route.query.businessId) ?? null;
    }
    if (this.$route.query.taskId) {
      this.taskId = String(this.$route.query.taskId) ?? '';
    }
    if (this.$route.query._id) {
      this.id = Number(this.$route.query._id) ?? null;
    }
    if (this.$route.query.bizKey) {
      this.bizKey = String(this.$route.query.bizKey) ?? '';
    }

    let componentNameStr = '';
    switch (this.bizKey) {
      case 'billTaskHandler':
        componentNameStr = 'BillingDetails';
        break;
      case 'billRepealHandler':
        componentNameStr = 'BillingDetails';
        break;
      case 'billInvoiceHandler':
        componentNameStr = 'InvoiceDetails';
        break;
    }
    this.componentName = componentNameStr;

    this.$nextTick(() => {
      switch (this.bizKey) {
        case 'billTaskHandler':
          (this.$refs['BillingDetails'] as BillingDetails).id = this.businessId!;
          (this.$refs['BillingDetails'] as BillingDetails).initDetails();
          break;
        case 'billRepealHandler':
          (this.$refs['BillingDetails'] as BillingDetails).id = this.businessId!;
          (this.$refs['BillingDetails'] as BillingDetails).initDetails();
          break;
        case 'billInvoiceHandler':
          (this.$refs['InvoiceDetails'] as InvoiceDetails).id = this.businessId!;
          (this.$refs['InvoiceDetails'] as InvoiceDetails).loadDetails(this.businessId!);
          break;
      }
      if (this.taskId) {
        this.showApprovalRecord();
      }
    });
  }

  public beforeRouteLeave(to: any, from: any, next: () => void): void {
    TagsViewModule.DelView(this.$route);
    next();
  }
  public close(): void {
    TagsViewModule.DelView(this.$route);
    console.log(this.$router);
    this.$router.go(-1);
  }
}
