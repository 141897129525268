import { render, staticRenderFns } from "./task-processing.vue?vue&type=template&id=3695d8c6&scoped=true&"
import script from "./task-processing.ts?vue&type=script&lang=ts&"
export * from "./task-processing.ts?vue&type=script&lang=ts&"
import style0 from "./task-processing.scoped.scss?vue&type=style&index=0&id=3695d8c6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3695d8c6",
  null
  
)

export default component.exports