import { messageError } from './../../../../../../../utils/index';
import { ApproveUser, TaskCenterDetailse, TreeNode } from '@/resource/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { todoTaskService } from '@/api';

@Component({
  name: 'Record',
  components: {}
})
export default class Record extends Vue {
  @Prop({
    required: true,
    type: Number,
    default: false
  })
  public id!: number;

  public worflowJson: Array<TreeNode> = [
    // {
    //   id: 'root',
    //   name: '发起人',
    //   props: {
    //     assignedUser: [{ name: '张亚锋', id: 158, isEdit: false, approveStatus: 1 }],
    //     assignedType: 'ASSIGN_USER',
    //     mode: 'NEXT'
    //   },
    //   status: 1 // 0 待审核 1 通过,  2 驳回
    // },
    // {
    //   id: 'node_522794867072',
    //   name: '审批人',
    //   props: {
    //     assignedType: 'DIRECT_MANAGER',
    //     assignedUser: [{ id: 90, name: '旺旺', isEdit: false, approveStatus: 1 }],
    //     mode: 'NEXT'
    //   },
    //   status: 1
    // },
    // {
    //   id: 'node_524710984547',
    //   name: '审批人',
    //   props: {
    //     assignedType: 'ASSIGN_USER',
    //     assignedUser: [
    //       { id: 3, name: '陈佳凯', approveStatus: 1 },
    //       { id: 4, name: '张红菊', approveStatus: 1 },
    //       { id: 5, name: '杨阳', approveStatus: 1 },
    //       { id: 6, name: '吴可婷', approveStatus: 2, context: '驳回' },
    //       { id: 7, name: '叶柳青', approveStatus: 1 },
    //       { id: 8, name: '黄莉莉', approveStatus: 2, context: '驳回' }
    //     ],
    //     mode: 'AND'
    //   },
    //   status: 0
    // },
    // {
    //   id: 'node_761703229558',
    //   name: '审批人',
    //   props: { assignedType: 'ASSIGN_USER', assignedUser: [{ id: 9, name: '张逸飞', isEdit: false }], mode: 'NEXT' },
    //   status: 0
    // },
    // {
    //   id: 'node_769418078463',
    //   name: '抄送人',
    //   props: { assignedType: 'DIRECT_MANAGER', assignedUser: [{ id: 90, name: '旺旺', isEdit: false }] },
    //   status: 0
    // },
    // {
    //   id: 'node_769486911404',
    //   name: '抄送人',
    //   props: {
    //     assignedType: 'ASSIGN_USER',
    //     assignedUser: [
    //       { id: 7, name: '叶柳青', isEdit: false, approveStatus: 0 },
    //       { id: 8, name: '黄莉莉', isEdit: false },
    //       { id: 9, name: '张逸飞', isEdit: false }
    //     ]
    //   },
    //   status: 0
    // }
  ];

  public userApproveStatus(approveStatus?: number): string {
    let str = '';
    switch (approveStatus) {
      case 1:
        str = '已同意';
        break;
      case 2:
        str = '已驳回';
        break;
      case 4:
        str = '已读';
        break;
      case 5:
        str = '未读';
        break;
      default:
        str = '待审核';
        break;
    }
    return str;
  }

  public getstatusClass(approveStatus?: number): string {
    switch (approveStatus) {
      case 0:
        return 'todo';
      case 1:
        return 'agree';
      case 5:
        return 'todo';
      case 4:
        return 'agree';
      case 2:
        return 'rejected';
      default:
        return 'todo';
    }
  }

  public tip(node: TreeNode): string {
    let tipStr = '';
    switch (node.props?.mode) {
      case 'AND':
        tipStr = '多人会签（全部通过即通过）';
        break;
      case 'OR':
        tipStr = '或签（一人通过即通过）';
        break;
    }

    if (node.props?.assignedType === 'DIRECT_MANAGER') {
      tipStr = '';
      // tipStr = '（直属领导）';
    }
    return tipStr;
  }
  public getAssignedUser(node: TreeNode): Array<ApproveUser> {
    const index = node.props?.assignedUser.findIndex(u => u.approveStatus === 2);
    if (index !== -1) {
      return node.props?.assignedUser.filter(u => [5, 4, 2, 1].includes(u.approveStatus!)) ?? [];
    } else if (node.props?.mode === 'OR') {
      const userList = node.props?.assignedUser.filter(u => [1, 2].includes(u.approveStatus!)) ?? [];
      if (userList.length > 0) {
        return userList;
      } else {
        return node.props?.assignedUser;
      }
    }
    return node.props?.assignedUser as Array<ApproveUser>;
  }

  public mergeData(
    json: Array<TreeNode>,
    logList: Array<{ context: string; createTime: string; status: number; userId: number; name: string }>
  ): Array<TreeNode> {
    logList.forEach(log => {
      json.forEach(node => {
        if (`${node.id}-${node.name}` === log.name) {
          node.props?.assignedUser.forEach(user => {
            if (user.id === log.userId) {
              user.approveStatus = log.status;
              user.createTime = log.createTime;
              user.context = log.context;
            }
          });
        }
      });
    });
    console.log(json);
    return json;
  }

  public handleNodeState(): void {
    this.worflowJson.forEach((node, i) => {
      if (node.props?.assignedUser) {
        node.status = this.getNodeStatus(node, i);
      }
    });
    const index = this.worflowJson.findIndex(node => node.status === 2);
    if (index !== -1) {
      this.worflowJson.splice(index + 1, this.worflowJson.length - index - 2);
    }
  }
  public getNodeStatus(node: TreeNode, i: number): number {
    const assignedUser = node.props?.assignedUser ?? [];
    const agreeNum = assignedUser.filter(u => u.approveStatus === 1).length;
    const userNum = assignedUser.length;
    const index = assignedUser.findIndex(u => u.approveStatus === 2);

    const agree = (): boolean => {
      if (node.props?.assignedType === 'ASSIGN_USER' && node.props?.mode === 'AND' && agreeNum === userNum) {
        return true;
      } else if (node.props?.assignedType === 'ASSIGN_USER' && node.props?.mode === 'OR' && agreeNum >= 1) {
        return true;
      } else if (node.props?.assignedType === 'DIRECT_MANAGER' && agreeNum === 1) {
        return true;
      } else if (['CC'].includes(node.type!)) {
        return i === 0 || this.worflowJson[i - 1].status === 1;
      } else if (agreeNum === userNum) {
        return true;
      } else {
        return false;
      }
    };
    if (index !== -1) {
      return 2;
    } else if (agree()) {
      return 1;
    } else if (node.type === 'end') {
      return this.worflowJson[i - 1].status as number;
    } else {
      return 0;
    }
  }

  public getDetailes(id: number): void {
    todoTaskService
      .getById(id)
      .then((res: TaskCenterDetailse) => {
        this.worflowJson = this.worflowJson.concat(this.mergeData(JSON.parse(res.json), res.logList));
        console.log('流程信息', JSON.parse(res.json));
        this.handleNodeState();
      })
      .catch(err => {
        messageError(err);
      });
  }
  public created(): void {
    this.getDetailes(this.id);
  }
}
