import { Component, Vue, Prop } from 'vue-property-decorator';
import TaskProcessing from './components/task-processing/task-processing.vue';
import Record from './components/record/record.vue';

@Component({
  name: 'ApprovalRecord',
  components: { TaskProcessing, Record }
})
export default class ApprovalRecord extends Vue {
  @Prop({
    required: true,
    type: Boolean,
    default: false
  })
  public drawer!: boolean;

  @Prop({
    required: true,
    type: String,
    default: ''
  })
  public taskId!: string;
  @Prop({
    required: true,
    type: Number,
    default: undefined
  })
  public id: number = 0;
  @Prop({
    required: true,
    type: Number,
    default: null
  })
  public businessId!: number;

  public activeName: string = 'first';

  private get _drawer(): boolean {
    return this.drawer;
  }
  private set _drawer(val: boolean) {
    (this.$parent as any).drawer = val;
  }

  public handleClick(tab: any, event: any): void {
    console.log(tab, event);
  }
  public operationSuccess(): void {
    this.$emit('operationSuccess');
  }
  public mounted(): void {
    if (!this.taskId) {
      this.activeName = 'second';
    }
  }
}
