import { todoTaskService } from '@/api';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'TaskProcessing',
  components: {}
})
export default class TaskProcessing extends Vue {
  @Prop({
    required: true,
    type: Number,
    default: false
  })
  public id!: number;
  @Prop({
    required: true,
    type: String,
    default: false
  })
  public taskId!: string;
  public submitLoading: boolean = false;
  public taskProcessingForm: {
    content: string;
  } = {
    content: '同意'
  };
  public taskProcessingFormRules = {
    content: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.input') + translation('taskCenter.content')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };
  public setLoading(value: boolean): void {
    this.submitLoading = value;
  }

  public aggre(): void {
    (this.$refs.taskProcessingForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      const formData = {
        id: this.id,
        taskId: this.taskId,
        content: this.taskProcessingForm.content
      };
      try {
        await todoTaskService.submitTask(formData as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operationSuccess');
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
  public reject(): void {
    (this.$refs.taskProcessingForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      const formData = {
        id: this.id,
        taskId: this.taskId,
        content: this.taskProcessingForm.content
      };
      try {
        await todoTaskService.rejectTask(formData as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operationSuccess');
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
